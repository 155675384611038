<template>
  <div>
    <h2 class="mb-6">{{$t('Inventory')}}</h2>
    <head-component :noActions="true" :statusFilters="merchants" @changeFilter="changeFilter" @filterValue="filterValue = $event"></head-component>
    <div class="lg:w-1/4 md:w-1/2 w-full ml-auto">
      <div class="inventory-card text-center w-full mb-4">
        <p class="font-medium text-darkblue">{{ $t('Total Current Inventory') }}</p>
        <p class="text-darkblue mt-1">{{ totalInventoryLevel }}</p>
      </div>
    </div>
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="products"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="flex flex-wrap gap-3 whitespace-nowrap">
          <print-label-button :selected="selected"></print-label-button>
          <div id="download-btn-loading">
            <div class="available-action flex px-4 py-2" @click="downloadInventory()">
              <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
              <span class="text-sm ml-2">{{ $t('Download') }}</span>
            </div>
          </div> 
        </div>
      </template>
      <template slot="thead">
        <shipblu-th>{{$t('Product Image')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Product Name')}}</shipblu-th>
        <shipblu-th>{{$t('SKU')}}</shipblu-th>
        <shipblu-th>{{$t('Size')}}</shipblu-th>
        <shipblu-th>{{$t('Color')}}</shipblu-th>
        <shipblu-th>{{$t('Dimensions')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Reserved Inventory')}}</shipblu-th>
        <shipblu-th>{{$t('Current Inventory')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-2">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].image" class="order-3">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Image')}}</p>
            <img :src=" data[indextr].image " class="w-10 h-10"/>
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].merchantName" class="order-4">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant Name')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].group.merchant.name }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].name" class="order-5">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Name')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].name }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].sku" class="text-left sm:order-2 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].sku }}
              </p>
            </div>
          </shipblu-td>


          <shipblu-td :data="data[indextr].size" class="order-6">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Size')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].size }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].color">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Color')}}</p>
            <div class="color-container h-6 sm:block flex items-center gap-2">
              <div class="w-full h-full" :style="`background-color:${data[indextr].color}`"></div>
              <p class="sm:mt-2 mt-0" v-if="!data[indextr].color.includes('#')">{{data[indextr].color}}</p>
            </div>
          </shipblu-td>


          <shipblu-td :data="data[indextr].dimensions" class="order-8">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Dimensions')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].d_length }} x {{ data[indextr].d_width }} x {{ data[indextr].d_height }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].location" class="order-9">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="link" @click="openLocationModal(data[indextr])">{{ data[indextr].locations.length }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].reserved_inventory" class="order-10">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Reserved Inventory')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].reserved_inventory }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].inventory" class="order-11">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Inventory')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].inventory }}
            </p>
          </shipblu-td>

        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    
    <shipblu-prompt
      @close="closeModal"
      class="shipment-modal"
      :active.sync="locationModal"
      :title="$t('Location')"
      :buttons-hidden="true">
      <div class="mb-4 shadow-drop rounded-lg flex justify-between items-center p-4" v-for="location in product.locations" :key="location.index">
        <span>{{location.location}}</span>
        <div>
          <span class="text-grey text-lg font-medium"> {{$t('Qty')}}{{': '}}</span>
          <span class="text-blue-100"> {{`[${location.stored_quantity}]`}}</span>
        </div>
      </div>
    </shipblu-prompt>
    <print-product-barcode :selected="selected"/>
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import PrintProductBarcode from '../merchant/components/PrintProductBarcode.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import HeadComponent from '../merchant/components/Header.vue'
import i18nData from '../../i18n/i18nData.js'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      locationModal: false,
      products: [],
      product: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      totalInventoryLevel: '',
      tableLoader: false,
      totalReservedInventory: '',
      merchants: [],
      filters: '',
      filterValue: ''
    }
  },
  watch : {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadProducts()
    }
  },
  methods: {
    downloadInventory () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#download-btn-loading',
        scale: 0.45
      })
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/products/export/', 'post', this.filters ? {merchant_id: Number(this.filters)} : {}, true, 
        (response) => {
          this.$vs.loading.close('#download-btn-loading > .con-vs-loading')
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
        }
      )
    },
    getstyle (status) {
      return `background: ${common.getOrderStatusColor(status)}`
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    changeFilter () {
      this.filters = ''
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: 1,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadProducts()
    },
    closeModal (modalName) {
      this.productModal = false
      this.locationModal = false
      if (modalName) {
        this.loadProducts()
      }
    },
    loadProducts () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&merchant=${this.filters}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/products/${query}`, 'get', null, true,
        (response) => {
          this.products = response.data.results
          this.totalRows = response.data.count
          this.totalInventoryLevel = response.data.total_inventory_level
          this.totalReservedInventory = response.data.total_reserved_inventory
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    openLocationModal (data) {
      this.product = data
      this.locationModal = true
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductCatalogSearch)
    },
    loadProductCatalogSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadProducts()
    },
    loadMerchants () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/merchants/?limit=100', 'get', null, true, 
        (response) => {
          this.merchants = response.data.results
          this.merchants.unshift({
            name: 'All',
            value: 'all'
          })
        })
    },
    printLabel (product) {
      this.printProduct = product
      window.print()
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPrompt,
    PrintLabelButton,
    PrintProductBarcode,
    ShipbluPagination,
    HeadComponent
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : ''
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadProducts()
    this.loadMerchants()
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
.color-container {
  border: 1px solid #000;
  border-radius: 7px;
  padding: 2px;
  div {
    border-radius: 5px;
  }
}
.inventory-card {
  background: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0,0,0,.1);
  -webkit-transition: all .3s ease-in-out;
}
</style>